import React, { useState } from "react";
import { Typography, AppBar, Toolbar, Button, Box, IconButton, Menu, MenuItem } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import { Link, useLocation } from "react-router-dom";

export const StickyAppBar = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const location = useLocation(); // Get current location

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const isSelected = (path) => location.pathname === path;

  return (
    <AppBar
      sx={{ position: "sticky", height: 75, justifyContent: "space-between" }}
    >
      <Toolbar
        sx={{ display: "flex", justifyContent: "space-between", width: "100%", height: '100%' }}
      >
        <Link to="/" style={{ textDecoration: "none", color: "#C8102E" }}>
          <Typography
            variant="h5"
            color="#C8102E"
            sx={{
              textAlign: "left",
              width: "100%",
              fontWeight: "bold",
              fontSize: { xs: "24px", md: "32px" },
              fontFamily: "Roboto, sans-serif",
            }}
          >
            Förseningskoll
          </Typography>
        </Link>
        <Box sx={{ display: { xs: 'none', md: 'flex'}, height: '100%' }}>
          <Button
            component={Link}
            to="/sok-forsening"
            color="inherit"
            sx={{
              padding: 3,
              fontWeight: 'bold',
              position: 'relative',
              '&::after': {
                content: '""',
                display: 'block',
                width: '100%',
                height: '2px',
                backgroundColor: '#C8102E',
                position: 'absolute',
                bottom: 4,
                left: 0,
                opacity: isSelected("/sok-forsening") ? 1 : 0,
                transition: 'opacity 0.3s',
              },
              '&:hover': {
                fontWeight: 'bold',
              },
            }}
          >
            Sök Försening
          </Button>
          <Button
            component={Link}
            to="/faq"
            color="inherit"
            sx={{
              padding: 3,
              fontWeight: 'bold',
              position: 'relative',
              '&::after': {
                content: '""',
                display: 'block',
                width: '100%',
                height: '2px',
                backgroundColor: '#C8102E',
                position: 'absolute',
                bottom: 4,
                left: 0,
                opacity: isSelected("/faq") ? 1 : 0,
                transition: 'opacity 0.3s',
              },
              '&:hover': {
                fontWeight: 'bold',
              },
            }}
          >
            Faq
          </Button>
        </Box>
        <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
          <IconButton
            color="inherit"
            onClick={handleMenuClick}
          >
            <MenuIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={handleMenuClose} component={Link} to="/sok-forsening">
              Sök försening
            </MenuItem>
            <MenuItem onClick={handleMenuClose} component={Link} to="/faq">
              Faq
            </MenuItem>
            {/* Add more menu items here if needed */}
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

import * as React from 'react';
import Box from '@mui/material/Box';
import { Link } from "react-router-dom";
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import HomeIcon from '@mui/icons-material/Home';
import ArticleIcon from '@mui/icons-material/Article';
import PolicyIcon from '@mui/icons-material/Policy';

export const Footer = () => {
    const [value, setValue] = React.useState(0);

    return (
        <Box>
            <BottomNavigation
                showLabels
                value={value}
                onChange={(event, newValue) => {
                    setValue(newValue);
                }}
            >
                <BottomNavigationAction
                    component={Link}
                    label="Hem"
                    to="/"
                    icon={<HomeIcon />}
                />
                <BottomNavigationAction
                    component={Link}
                    to="/privacy"
                    label="Integritetspolicy"
                    icon={<PolicyIcon />}
                />
                <BottomNavigationAction
                    component={Link}
                    to="/terms"
                    label="Vilkor"
                    icon={<ArticleIcon />}
                />
            </BottomNavigation>
        </Box>
    );
}

export default Footer;
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import SearchForm from "./SearchForm";
import TripCard from "./TripCard";
import { sortTripsByDeparture, filterTripsWithDelays, adjustDateOfTrip } from "../util/tripsUtil";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";

export const SearchResult = () => {
  const location = useLocation();
  const { fromState, toState, dateState, errorState, ticketPriceState, ticketTypeState } = location.state || {};
  const [trips, setTrips] = useState([]);

  useEffect(() => {
    if (!errorState && fromState && toState && dateState) {
      fetchTrips();
    }
  }, [errorState, fromState, toState, dateState]);

  const fetchTrips = async () => {
    try {
      const response = await fetch(
      "/api/trips?" +
        new URLSearchParams({
        from_stop: fromState,
        to_stop: toState,
        date: dateState,
      })
    );
    const json = await response.json();
    const sortedTrips = sortTripsByDeparture(json);
    adjustDateOfTrip(sortedTrips);
    const filteredTrips = filterTripsWithDelays(sortedTrips, 1200);
    setTrips(filteredTrips);
    } catch (error) {}
  };

  return (
    <Box>
      {!errorState && (
        <Box
          sx={{
            width: "100%",
            maxWidth: "800px",
            mt: 2,
            zIndex: 20,
            padding: {
              xs: 1,
              sm: 1,
            },
          }}
        >
          {trips.length > 0 ? (
            <Box>
              <Box
                variant="outlined"
                component="section"
                sx={{
                  padding: {
                    xs: 1,
                    sm: 1,
                  },
                  mb: 2,
                  border: "1px dashed grey",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "5px",
                }}
              >
                <Typography variant="body1" color="black">
                  Förseningar som kan ge rätt till ersättning
                </Typography>
              </Box>
              {trips.map((trip, index) => (
                <TripCard
                  key={index}
                  trip={trip}
                  ticketPrice={ticketPriceState}
                  ticketType={ticketTypeState}
                />
              ))}
            </Box>
          ) : (
            <Alert severity="info">
              Inga förseningar som matchade din sökning.
            </Alert>
          )}
        </Box>
      )}
    </Box>
  );
};


import React from "react";
import FAQ, { SearchForm } from "../components/Faq";
import { Box, Typography } from "@mui/material";


const Main = () => {
  return (
    <>
     <Box sx={{ padding: "20px", maxWidth: "800px", margin: "0 auto" }}>
        <FAQ />
    </Box>
    </>
  );
};

export default Main;
import React from "react";
import { Box } from "@mui/material";
import { SearchForm } from "../components/SearchForm";
import {SearchResult} from "../components/SearchResult"
import Banner from "../components/Banner";
import PageContent from "../components/PageContent";
import { Footer} from "../components/Footer";

const SearchPage = () => {
  return (
    <>
    <Banner title={["F\u00F6rsenad i Skåne?", "" , "Du kan ha r\u00E4tt till ers\u00E4ttning"]} />
      <PageContent>
        <SearchForm/> 
        <SearchResult/>
      </PageContent>
    </>
  );
};

export default SearchPage;
import React from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InfoIcon from "@mui/icons-material/Info";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import Divider from "@mui/material/Divider";

export const TripCard = ({ trip, ticketType, ticketPrice }) => {
  const seconds_to_full_minutes_str = (seconds, addPositivePrefix) => {
    const minutes = Math.floor(seconds / 60);
    let prefix = "";
    if (addPositivePrefix) {
      prefix = "+";
    }
    if (minutes < 0) {
      prefix = "-";
    }

    return prefix + " " + Math.abs(minutes).toString();
  };

  const calculateTripReimbursement = (delay, ticketType, ticketPrice) => {
    if (ticketPrice == null || ticketType == null) {
      return 0;
    }

    let reimbursment = 0;
    let factor = 0;
    const delayInMinutes = Math.floor(delay / 60);
    if (delayInMinutes >= 20 && delayInMinutes < 40) {
      factor = 0.5;
    } else if (delayInMinutes >= 40 && delayInMinutes < 60) {
      factor = 0.75;
    } else if (delayInMinutes >= 60) {
      factor = 1.0;
    }

    switch (ticketType) {
      case "enkel":
        reimbursment = factor * ticketPrice;
        break;
      case "24":
        reimbursment = (factor * ticketPrice) / 2;
        break;
      case "30":
        reimbursment = (factor * ticketPrice) / 20;
        break;
      case "flex":
        reimbursment = (factor * ticketPrice) / 10;
        break;
      case "sommar":
        reimbursment = (factor * ticketPrice) / 40;
        break;
      default:
        reimbursment = 0;
    }

    return Math.ceil(reimbursment.toFixed(2));
  };

  return (
    <Card
      variant="outlined"
      sx={{
        width: "100%",
        maxWidth: "800px",
        mb: 2,
        borderColor: "text.secondary",
      }}
    >
      <CardContent>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{ width: "100%" }}
        >
          <Typography variant="body2">{trip.date}</Typography>
          <Typography variant="body2" color="success" sx={{ mr: 1 }}>
            <strong>
              Försening{" "}
              {seconds_to_full_minutes_str(
                trip.stops[trip.stops.length - 1].arrival_delay,
                false
              )}{" "}
              minuter{" "}
            </strong>
          </Typography>
        </Box>

        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{ width: "100%" }}
        >
          <Typography variant="h6">
            <strong>
              {trip.stops[0].scheduled_departure_time} →{" "}
              {trip.stops[trip.stops.length - 1].scheduled_arrival_time}
            </strong>
          </Typography>

          <Box display="flex" alignItems="center" sx={{ mr: 1 }}>
            {trip.stops[trip.stops.length - 1].arrival_uncertainty == null && (
              <Typography
                variant="body2"
                color="warning"
                sx={{ display: "inline-block", verticalAlign: "middle" }}
              >
                <strong>Osäkerhet mellan</strong>
              </Typography>
            )}
            {trip.stops[trip.stops.length - 1].arrival_uncertainty === 0 ? (
              <Typography
                variant="body2"
                color="success"
                sx={{ display: "inline-block", verticalAlign: "middle" }}
              >
                <strong>Osäkerhet låg</strong>
              </Typography>
            ) : trip.stops[trip.stops.length - 1].arrival_uncertainty ? (
              <Typography
                variant="body2"
                color="warning"
                sx={{ display: "inline-block", verticalAlign: "middle" }}
              >
                <strong>Osäkerhet mellan</strong>
              </Typography>
            ) : null}
          </Box>
        </Box>

        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{ width: "100%" }}
        >
          <Typography variant="body1">
            {trip.from_stop} → {trip.to_stop}
          </Typography>
        </Box>

        {trip.cancelled && (
          <Typography variant="body2" color="textSecondary">
            Inställd
          </Typography>
        )}

        <Box sx={{ mt: 1 }}>
          {trip.stops
            .slice(1)
            .some(
              (stop) =>
                stop.arrival_delay >
                trip.stops[trip.stops.length - 1].arrival_delay
            ) && (
            <Box display="flex" alignItems="center" sx={{ mt: 2, mb: 2 }}>
              <PriorityHighIcon color="info" />
              <Typography
                variant="body2"
                color="info"
                sx={{ mt: 0.2, justifyContent: "center" }}
              >
                Större försening för mellanliggande hållplats
              </Typography>
            </Box>
          )}
          <Accordion sx={{ boxShadow: "none" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{ flexDirection: "row-reverse" }}
            >
              <Typography variant="body2" sx={{ ml: 3 }}>
                Visa mellanliggande hållplatser
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {trip.stops.slice(1).map((stop, index) => (
                <Box key={index} sx={{ mb: 2 }}>
                  <Typography variant="body1">{stop.stop_name}</Typography>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ width: "100%" }}
                  >
                    <Typography variant="body1" color="textSecondary">
                      Ankomst: {stop.scheduled_arrival_time}
                      {stop.arrival_delay !== null ? (
                        <strong>
                          {" "}
                          {seconds_to_full_minutes_str(
                            stop.arrival_delay,
                            true
                          )}{" "}
                          min
                        </strong>
                      ) : (
                        ""
                      )}
                    </Typography>
                    <Box display="flex" alignItems="center">
                      {ticketPrice !== null && ticketPrice !== "" && (
                        <Typography
                          variant="body1"
                          color="textSecondary"
                          sx={{
                            display: "inline-block",
                            verticalAlign: "middle",
                          }}
                        >
                          <strong>
                            {calculateTripReimbursement(
                              stop.arrival_delay,
                              ticketType,
                              ticketPrice
                            )}{" "}
                            kr
                          </strong>
                        </Typography>
                      )}

                      <IconButton>
                        <InfoIcon
                          color="textSecondary"
                          fontSize="small"
                          titleAccess={`Potentiell ersättning för denna hållplatsen. Sök från ${trip.from_stop} till ${stop.stop_name} för mer detaljer`}
                        />
                      </IconButton>
                    </Box>
                  </Box>

                  <Typography variant="body1" color="textSecondary">
                    Avgång: {stop.scheduled_departure_time}
                    {stop.departure_delay !== null ? (
                      <strong>
                        {" "}
                        {seconds_to_full_minutes_str(
                          stop.departure_delay,
                          true
                        )}{" "}
                        min
                      </strong>
                    ) : (
                      ""
                    )}
                  </Typography>
                </Box>
              ))}
            </AccordionDetails>
          </Accordion>
        </Box>

        {trip.alternatives && trip.alternatives.length > 0 && (
          <Box sx={{ mt: 2 }}>
            <Divider />
            <Box display="flex" alignItems="center" sx={{ mt: 2, mb: 2 }}>
              <PriorityHighIcon color="warning"></PriorityHighIcon>
              <Typography
                variant="body2"
                color="warning"
                sx={{ mt: 0.2, justifyContent: "center" }}
              >
                Alternativa resor kan påverka ersättningen
              </Typography>
            </Box>

            <Accordion sx={{ boxShadow: "none" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{ flexDirection: "row-reverse" }}
              >
                <Typography variant="body2" sx={{ ml: 3 }}>
                  Visa alternativ
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {trip.alternatives.map((altTrip, index) => (
                  <Box key={index} sx={{ mb: 2 }}>
                    <Typography variant="body1">
                      {altTrip.stops[0].scheduled_departure_time}{" "}
                      {altTrip.stops[altTrip.stops.length - 1]
                        .departure_delay !== null
                        ? seconds_to_full_minutes_str(
                            altTrip.stops[altTrip.stops.length - 1]
                              .departure_delay,
                            true
                          )
                        : ""}{" "}
                      min →{" "}
                      {
                        altTrip.stops[altTrip.stops.length - 1]
                          .scheduled_arrival_time
                      }{" "}
                      {altTrip.stops[altTrip.stops.length - 1].arrival_delay !==
                      null
                        ? seconds_to_full_minutes_str(
                            altTrip.stops[altTrip.stops.length - 1]
                              .arrival_delay,
                            true
                          )
                        : ""}{" "}
                      min
                    </Typography>
                    <Typography variant="body1" color="textSecondary">
                      {altTrip.from_stop} → {altTrip.to_stop}
                    </Typography>
                  </Box>
                ))}
              </AccordionDetails>
            </Accordion>
          </Box>
        )}
      </CardContent>
    </Card>
  );
};
export default TripCard;

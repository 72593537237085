const parseTime = (timeStr) => {
    const [hours, minutes] = timeStr.split(':').map(Number);
    const adjustedHours = hours >= 24 ? hours - 24 : hours;

    const date = new Date();
    date.setHours(adjustedHours);
    date.setMinutes(minutes);

    if (hours >= 24) {
        date.setDate(date.getDate() + 1);
    }

    return date;
};

export const sortTripsByDeparture = (trips) => {
    return trips.sort((a, b) => {
        const timeA = parseTime(a.stops[0].scheduled_departure_time);
        const timeB = parseTime(b.stops[0].scheduled_departure_time);
        return timeA - timeB;
    })
};

export const adjustDateOfTrip = (trips) => {
    trips.forEach(trip => {
        const first_stop = trip.stops[0];
        if (first_stop !== null) {
            const [hours, minutes] = first_stop.scheduled_departure_time.split(':').map(Number);
            if (hours >= 24) {
                // This trip started next day from that station.
                const date = new Date(trip.date);
                date.setDate(date.getDate() + 1);
                trip.date = date.toISOString().split('T')[0];
            }
        }
        trip.stops.forEach(stop => {
            const scheduled_departure_time = parseTime(stop.scheduled_departure_time);
            const scheduled_departure_time_hours = scheduled_departure_time.getHours();
            const scheduled_departure_time_minutes = scheduled_departure_time.getMinutes();
            stop.scheduled_departure_time =
                `${scheduled_departure_time_hours.toString().padStart(2, '0')}:${scheduled_departure_time_minutes.toString().padStart(2, '0')}`;

            const scheduled_arrival_time = parseTime(stop.scheduled_arrival_time);
            const scheduled_arrival_time_hours = scheduled_arrival_time.getHours();
            const scheduled_arrival_time_minutes = scheduled_arrival_time.getMinutes();
            stop.scheduled_arrival_time = `${scheduled_arrival_time_hours.toString().padStart(2, '0')}:${scheduled_arrival_time_minutes.toString().padStart(2, '0')}`;
        });
    });
}

const dateFromDateAndTime = (timeString, dateString) => {
    const [hours, minutes] = timeString.split(':').map(Number);
    const date = new Date(dateString);
    date.setHours(hours);
    date.setMinutes(minutes);
    date.setSeconds(0);
    return date;
}

export const normalizeString = (str) => {
    return str
        .replace(/københavn/g, 'köpenhamn')
        .replace(/København/g, 'Köpenhamn')
        .replace(/havn/g, 'hamn')
        .replace(/Køben/g, 'Köpen')
        .replace(/køben/g, 'köpen')
        .replace(/ø/g, 'ö')
        .replace(/Ø/g, 'Ö')
        .replace(/æ/g, 'ä')
        .replace(/Æ/g, 'Ä')
        .replace(/å/g, 'å')
        .replace(/Å/g, 'Å');
};


/// Filter trips that have any stops with more than minDelay
export const filterTripsWithDelays = (trips, minDelay) => {

    let trips_filtered_by_delays = trips.filter((trip) => trip.stops.slice(1).some((stop) => stop.arrival_delay >= minDelay))

    trips_filtered_by_delays = trips_filtered_by_delays.map(trip => {
        const last_stop_of_trip = trip.stops[trip.stops.length - 1];
        const delayed_arrival_time = dateFromDateAndTime(last_stop_of_trip.scheduled_arrival_time, trip.date);
        delayed_arrival_time.setSeconds(delayed_arrival_time.getSeconds() + minDelay);

        const trip_first_stop = trip.stops[0];
        const departure_time = dateFromDateAndTime(trip_first_stop.scheduled_departure_time, trip.date);

        let alternatives = [];

        trips.forEach(alternative_trip => {
            if (!alternative_trip.cancelled) {
                const first_stop_of_alternative = alternative_trip.stops[0];
                const last_stop_of_alternative = alternative_trip.stops[alternative_trip.stops.length - 1];

                // Get alternative trip's departure and arrival times
                let alternative_departure_time = dateFromDateAndTime(first_stop_of_alternative.scheduled_departure_time, alternative_trip.date);
                let alternative_arrival_time = dateFromDateAndTime(last_stop_of_alternative.scheduled_arrival_time, alternative_trip.date);

                // If the arrival time is past midnight, check if it crosses to the next day
                if (alternative_arrival_time.getHours() < alternative_departure_time.getHours()) {
                    // Assume this means arrival is on the next day
                    alternative_arrival_time.setDate(alternative_arrival_time.getDate() + 1);
                }

                alternative_arrival_time.setSeconds(alternative_arrival_time.getSeconds() + last_stop_of_alternative.arrival_delay)

                if (!last_stop_of_alternative.skipped && !first_stop_of_alternative.skipped && alternative_departure_time >= departure_time && alternative_arrival_time < delayed_arrival_time) {
                    alternatives.push(alternative_trip);
                }

            }
        });
        return {
            ...trip,
            alternatives
        }
    });

    return trips_filtered_by_delays;
}

import React from 'react';
import { Typography, Box, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const FAQ = () => {
  const faqs = [
    {
      question: "Vad är forsening.se?",
      answer: "forsening.se är en tjänst som hjälper dig att hitta förseningar för resor du gjort med Skånetrafiken."
    },
    {
      question: "Varför gör ni detta?",
      answer: "För att din tid är viktig och om du blivit försenad så ska du enkelt kunna hitta din försening för att få ersättning."
    },
    {
      question: "Hur fungerar det?",
      answer: "Du anger din avrese- och destinationsstation samt datum, och vi visar eventuella förseningar som skulle kunna ge dig rätt till förseningsersättning."
    },
    {
      question: "Erbjuder ni ersättning för förseningar?",
      answer: (
        <>
          Nej, vi tillhandahåller bara information om eventuella förseningar. För ersättning, vänligen kontakta Skånetrafiken direkt. 
          Du gör det lättast på <span> </span>  
          <a href="https://www.skanetrafiken.se/kundservice/forseningsersattning" target="_blank" rel="noopener noreferrer" style={{ color: '#C8102E', textDecoration: 'underline' }}>
             skånetrafiken.se
          </a>
        </>
      )
    },
    {
      question: "Vad betyder Alternativa resor kan påverka ersättningen i sökresultatet?",
      answer: "Det betyder att förseningen som vi hittat som eventuellt skulle ha kunnat ge dig rätt till ersättning möjligtvis inte är giltig då vi hittat ett resealternativ som kunde tagits för att undvika förseningen."
    },
    {
      question: "Jag blev nekad ersättning från skånetrafiken även om den var listad som försenad på er sida, varför?",
      answer: "Vi gör vårt bästa för att tillhandahålla korrekt information om förseningar, tyvärr så är datan inte alltid 100 procent exakt. Om du är helt säker på att du var försenad råder vi dig att ta kontakt med skånetrafiken och överklaga beslutet."
    },
    {
      question: "Visar ni förseningar för alla typer av transportmedel?",
      answer: "För tillfället visar vi bara förseningar för resor gjorda med tåg och regionsbussar."
    },
    {
      question: "Visar ni förseningar för resor över öresundsbron till och från danmark?",
      answer: "Ja."
    },
  ];

  return (
    <Box sx={{ padding: 3, maxWidth: '800px', margin: '0 auto' }}>
      <Typography variant="h3" gutterBottom sx={{ textAlign: 'center', fontWeight: 'bold' }}>
        Vanliga Frågor (FAQ)
      </Typography>
      {faqs.map((faq, index) => (
        <Accordion
          key={index}
          sx={{
            margin: '10px 0',
            border: '1px solid',
            borderRadius: '8px',
            boxShadow: '0px 4px 10px rgba(0,0,0,0.1)',
            '&:hover': {
              boxShadow: '0px 6px 15px rgba(0,0,0,0.2)',
            }
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{
              backgroundColor: '#f9f9f9',
              '&:hover': {
                backgroundColor: '#f0f0f0',
              }
            }}
          >
            <Typography
              sx={{
                fontWeight: 'bold',
              }}
            >
              {faq.question}
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              backgroundColor: '#fdfdfd',
              padding: '16px',
              color: '#555',
            }}
          >
            <Typography>{faq.answer}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
};

export default FAQ;

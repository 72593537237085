import React from "react";
import { Typography, Box } from "@mui/material";

const PageTitle = ({ text }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center", // Center horizontally
        alignItems: "center",     // Center vertically
        position: "absolute",
        width: "100%",
        top: { xs: "80px", md: "60px" },
      }}
    >
      <Typography
        variant="h1"
        sx={{
          color: "#F1C200",
          fontWeight: "bold",
          fontSize: { xs: "28px", md: "50px" },
          textShadow: "2px 2px 4px rgba(0, 0, 0, 0.9)",
          textAlign: "center",
          fontFamily: "Roboto, sans-serif",
        }}
      >
        {text.map((line, index) => (
          <span key={index}>
            {line}
            <br />
          </span>
        ))}
      </Typography>
    </Box>
  );
};

export default PageTitle;

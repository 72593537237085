import React from "react";
import { Box, Typography } from "@mui/material";

const TermsAndConditions = () => {
  return (
    <Box sx={{ padding: "20px", maxWidth: "800px", margin: "0 auto" }}>
      <Typography variant="h4" gutterBottom>
        Terms and Conditions
      </Typography>
      <Typography variant="body1" paragraph>
        Welcome to our website. By accessing and using this website, you agree to the following terms and conditions.
      </Typography>
      
      <Typography variant="h6" gutterBottom>
        Disclaimer of Reimbursement
      </Typography>
      <Typography variant="body1" paragraph>
        This website provides information about delayed trips made by Skånetrafiken that may entitle you to reimbursement. However, we do not provide reimbursements. Any reimbursement requests or claims should be made directly to Skånetrafiken.
      </Typography>

      <Typography variant="h6" gutterBottom>
        Accuracy of Information
      </Typography>
      <Typography variant="body1" paragraph>
        While we strive to provide accurate information about trip delays, we cannot guarantee the completeness or accuracy of the data. There is no guarantee that a trip was delayed or that you will be eligible for reimbursement from Skånetrafiken, even if the information indicates a delay.
      </Typography>

      <Typography variant="h6" gutterBottom>
        Ethical Use of Data
      </Typography>
      <Typography variant="body1" paragraph>
        You are responsible for using the data on this website in an ethical manner. This includes being truthful about the trips you have taken. Any false claims or misuse of the data may result in legal consequences or the rejection of reimbursement requests.
      </Typography>

      <Typography variant="h6" gutterBottom>
        No Affiliation with Skånetrafiken
      </Typography>
      <Typography variant="body1" paragraph>
        This website is not affiliated with, endorsed by, or associated with Skånetrafiken in any way. We operate independently and provide this service to help users find information about potential reimbursement opportunities for delayed trips. All official decisions regarding reimbursements are made solely by Skånetrafiken.
      </Typography>

      <Typography variant="h6" gutterBottom>
        Changes to Terms
      </Typography>
      <Typography variant="body1" paragraph>
        We reserve the right to update these terms and conditions at any time without prior notice. By continuing to use the website, you agree to the updated terms and conditions.
      </Typography>
    </Box>
  );
};

export default TermsAndConditions;
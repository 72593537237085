import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { StickyAppBar } from "./components/AppBar";
import Main from "./view/Main";
import PrivacyPolicy from "./view/PrivacyPolicy";
import { Box, Divider } from "@mui/material";
import { Footer } from "./components/Footer";
import TermsAndConditions from "./view/TermsAndConditions";
import SearchPage from "./view/SearchPage";
import Faq from "./view/Faq";

function App() {
    return (
        <Router>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    minHeight: "100vh", // Make sure the container takes the full viewport height
                }}
            >
                <StickyAppBar />
                <Box sx={{ flexGrow: 1 }}>
                    <Routes>
                        <Route path="/" element={<Main />} />
                        <Route path="/privacy" element={<PrivacyPolicy />} />
                        <Route path="/terms" element= {<TermsAndConditions/>}/>
                        <Route path="/sok-forsening" element= {<SearchPage/>}/>
                        <Route path="/faq" element= {<Faq/>}/>
                    </Routes>
                </Box>
                <Divider sx={{mt:2}}></Divider>
                <Footer />
            </Box>
        </Router>
    );
}

export default App;

import React, { useState, useEffect } from "react";

import "../css/SearchForm.css";
import TicketRadioButtonGroup from "./TicketRadioButton";
import {
  normalizeString,
} from "../util/tripsUtil";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Box,
  Typography,
  TextField,
  Button,
  Autocomplete,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
} from "@mui/material";

import { useNavigate, useLocation } from "react-router-dom";
import Alert from "@mui/material/Alert";
import InputAdornment from "@mui/material/InputAdornment";
import SwapVertIcon from "@mui/icons-material/SwapVert"; // Import a swap icon
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"; // Use this if you're using dayjs
import dayjs from "dayjs";

export const SearchForm = () => {
  const location = useLocation();
  const { fromState = "", toState = "", ticketPriceState = "", ticketTypeState = "enkel", errorState = "" } = location.state || {};

  const [from, setFrom] = useState(fromState);
  const [stops, setStops] = useState([]);
  const [to, setTo] = useState(toState);
  const [ticketPrice, setTicketPrice] = useState(ticketPriceState);
  const [ticketType, setTicketType] = useState(ticketTypeState);
  const [error, setError] = useState(errorState);

  useEffect(() => {
    const fetchStops = async () => {
      try {
        const response = await fetch("/api/stops");
        const data = await response.json();
        setStops(data);
      } catch (error) {
        console.error("Error fetching stops:", error);
      }
    };

    fetchStops();
  }, []);

  const handleInputChange = async (value, setter) => {
    setter(value);
    setError("");
  };

  const navigate = useNavigate(); 
  const handleOnSubmit = async () => {
    let errorMsg = '';

    if (!from || !to) {
      errorMsg = 'Både "Från" och "Till" måste vara ifyllda.'
    }

    if (from === to) {
      errorMsg = '"Från" och "Till" kan inte vara samma hållplats.'
    }

    setError(errorMsg);
    const formattedDate = date ? date.format("YYYY-MM-DD") : null;
    navigate("/sok-forsening", { state: { fromState: from, toState: to, dateState: formattedDate, errorState: errorMsg, ticketPriceState: ticketPrice, ticketTypeState: ticketPrice } })
  };

  const minDate = dayjs().subtract(30, "day");
  const maxDate = dayjs().subtract(1, "day");
  const [date, setDate] = useState(maxDate);

  const handlePriceInputChange = async (event, setter) => {
    const value = event.target.value;
    const numericValue = value.replace(/\D/g, "");

    if (value === "") {
      setter(null);
    } else {
      setter(numericValue);
    }
  };

  const handleSwap = () => {
    const temp = from;
    setFrom(to);
    setTo(temp);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
      }}
    >
      <Box
        className="search-form"
        sx={{
          width: "100%",
          maxWidth: "800px",
          padding: {
            xs: 2,
            sm: 8,
          },
        }}
      >
        <Typography
          variant="h2"
          className="form-header"
          align="center"
          sx={{ fontSize: "1.7rem", mb: 2, fontFamily: "Roboto, sans-serif" }}
        >
          Hitta din försening
        </Typography>

        {/* Input - From */}
        <Box
          className={`input-group`}
          sx={{ mb: 2, display: "flex", flexDirection: "column" }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              mb: 2,
              mt: 1,
              width: "100%",
            }}
          >
            <Typography
              variant="body1"
              className="input-label"
              sx={{ minWidth: "50px" }}
            >
              Från
            </Typography>
            <Autocomplete
              options={stops}
              value={from}
              freeSolo
              onChange={(e, newValue) => setFrom(newValue || "")}
              inputValue={from}
              onInputChange={(e, newValue) =>
                handleInputChange(newValue, setFrom)
              }
              filterOptions={(options, { inputValue }) => {
                const normalizedInput = normalizeString(
                  inputValue.toLowerCase()
                );
                return options.filter((option) =>
                  normalizeString(option.toLowerCase()).includes(
                    normalizedInput
                  )
                );
              }}
              fullWidth
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  placeholder="Hållplats"
                />
              )}
            />
          </Box>

          {/* Input To */}
          <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
            <Typography
              variant="body1"
              className="input-label"
              sx={{ minWidth: "50px" }}
            >
              Till
            </Typography>
            <Autocomplete
              options={stops}
              value={to}
              freeSolo
              onChange={(e, newValue) => setTo(newValue || "")}
              inputValue={to}
              onInputChange={(e, newValue) =>
                handleInputChange(newValue, setTo)
              }
              filterOptions={(options, { inputValue }) => {
                const normalizedInput = normalizeString(
                  inputValue.toLowerCase()
                );
                return options.filter((option) =>
                  normalizeString(option.toLowerCase()).includes(
                    normalizedInput
                  )
                );
              }}
              fullWidth
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  placeholder="Hållplats"
                />
              )}
            />
          </Box>

          {/* Swap Button */}
          <IconButton
            onClick={handleSwap}
            sx={{
              position: "absolute",
              top: { xs: "108px", sm: "156px", md: "156px" }, // Adjust top position for different screen sizes
              right: { xs: "15px", sm: "60px", md: "60px" }, // Adjust right position for different screen sizes
              borderRadius: "50%",
              backgroundColor: "#F1C200", // Button color
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", // Add some shadow for pop
              width: { xs: "40px", sm: "40px", md: "40px" }, // Adjust button size for different screen sizes
              height: { xs: "40px", sm: "40px", md: "40px" }, // Adjust button size for different screen sizes
            }}
          >
            <SwapVertIcon
              sx={{
                color: "white",
                fontSize: { xs: "20px", sm: "25px", md: "30px" },
              }}
            />{" "}
            {/* Icon size */}
          </IconButton>
        </Box>
        <Typography
          variant="h3"
          align="left"
          sx={{  fontSize: "1.2rem", mb: 4, mt: 4, ml: 2, fontFamily: "Roboto, sans-serif" }}
        >
          När blev du försenad?
        </Typography>
        <Box
          className="date-button-row"
          sx={{
            display: "flex",
            alignItems: "center",
            mb: 4,
            ml: 2,
            width: "100%",
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box sx={{ flexGrow: 1 }}>
              <DatePicker
                label="Datum"
                value={date}
                onChange={(newDate) => setDate(newDate)}
                fullWidth
                minDate={minDate}
                maxDate={maxDate}
                renderInput={(params) => <TextField {...params} />}
              />
            </Box>
          </LocalizationProvider>
        </Box>
        { /* <Accordion sx={{ boxShadow: "none", border: "none", mb: 2 }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ width: "100%" }} />}
          >
            <Typography
              variant="body2"
              align="center"
              sx={{
                ml: 2,
                mt: 1,
                width: "100%",
                textDecoration: "underline",
              }}
            >
              <strong>Biljettdetaljer</strong>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                mb: 2,
                ml: 2,
                width: "100%",
              }}
            >
              <Box sx={{ flexGrow: 1, flexBasis: 0, mr: 2 }}>
                <TicketRadioButtonGroup
                  ticketType={ticketType}
                  setTicketType={setTicketType}
                />
              </Box>
            </Box>

            <Box sx={{ flexGrow: 1, mb: 4, ml: 2, width: "100%" }}>
              <TextField
                id="outlined-basic"
                label="Biljettens pris"
                variant="outlined"
                value={ticketPrice}
                onChange={(newValue) =>
                  handlePriceInputChange(newValue || "", setTicketPrice)
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">kr</InputAdornment>
                  ),
                  inputMode: "numeric",
                  pattern: "[0-9]*",
                }}
              />
            </Box>
          </AccordionDetails>
        </Accordion>
        */}

        <Box sx={{ flexGrow: 1 }}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            className="search-button"
            fullWidth
            onClick={handleOnSubmit}
            sx={{
              minHeight: "56px",
              textTransform: "none",
              fontWeight: "bold",
              fontFamily: "Roboto, sans-serif",
            }}
          >
            Sök försening
          </Button>
        </Box>
          {error && (
          <Box
            sx={{
              width: "100%",
              maxWidth: "800px",
              mt: 2,
              zIndex: 20,
              padding: {
                xs: 1,
                sm: 1,
              },
            }}
          >
            <Alert severity="error">{error}</Alert>
          </Box>
        )}
      </Box>
    </Box>
  );
};

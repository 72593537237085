import React from "react";
import { Box } from "@mui/material";
import { Footer} from "../components/Footer";

const PageContent = ({ children }) => {
  return (
    <Box
      sx={{
        position: "relative",
        marginTop: "-350px",
        left: "50%",
        transform: "translateX(-50%)",
        zIndex: 10,
        width: "100%",
        maxWidth: "800px",
      }}
    >
      {children}
    </Box>
  );
};

export default PageContent;

import React from "react";
import { Box } from "@mui/material";
import PageTitle from "./PageTitle"; //

const Banner = ({ title }) => {
  return (
    <Box sx={{ position: "relative", mb: 5 }}>
      <img
        src="/banner.jpg"
        alt="Banner"
        style={{ width: "100%", height: "600px", objectFit: "cover" }}
      />
      <PageTitle text={title} />
    </Box>
  );
};

export default Banner;
